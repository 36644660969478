.darkbg {
    background: $black;
    box-shadow: 0 0 20px #000;
}

nav {
    position: fixed; // display:none;
    top: 0;
    z-index: 5;
    width: 100%;
    transition:1s all;
    .hamburger {
        cursor: pointer;
        $hamheight: 20px;
        $spanh: $hamheight / 10;
        height: $hamheight;
        width: $hamheight * 1.3;
        position: absolute;
        display: none; // background: red;
        z-index: 10;
        right: 0;
        top: ($navheight - $hamheight)/2;
        span {
            position: absolute;
            height: $spanh;
            width: 100%;
            border-radius: 1px;
            display: block;
            background: #FFF;
            transition: 0.5s;
        }
        span:nth-child(1) {
            top: 0;
        }
        span:nth-child(2) {
            top: ($hamheight - $spanh) /2;
        }
        span:nth-child(3) {
            bottom: 0;
        } // &:hover span {
        //     background: $accent;
        // }
        &.open {
            span:nth-child(1) {
                top: ($hamheight - $spanh) /2;
                opacity: 0;
                transform-origin: 50% 50%;
            }
            span:nth-child(2) {
                transform: rotate(135deg);
            }
            span:nth-child(3) {
                top: ($hamheight - $spanh) /2;
                transform: rotate(-135deg);
            }
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        float: right;
        font-size: 1.1em;
        li {
            height: $navheight;
            line-height: $navheight;
            margin: 0;
            padding: 0;
            display: inline-block;
            a {
                margin: 0;
                padding: 0;
                text-decoration: none;
                display: block;
                color: #FFF;
                text-transform: uppercase;
                margin: 0 20px 0 0;
                font-weight: 700;
                transition: color 0.25s;
                &:hover, &.active {
                    color: $accent;
                }
            }
        }
    }
    &::after {
        content: ' ';
        clear: both;
    }
}

a.nav-logo {
    // width:100px;
    height: $navheight;
    line-height: $navheight;
    padding: 0 0 0 100px;
    float: left;
    display: inline-block;
    text-decoration: none;
    color: #FFF;
    position: relative;
    font-size: 2em;
    font-weight: 700; // text-transform: uppercase;
    img {
        height: $navheight / 2;
        top: ($navheight / 2) /2;
        left: 0;
        position: absolute;
    }
}

@media(max-width:1280px) {
    nav {
        ul {
            font-size: 0.9em;
        }
    }
    a.nav-logo {
        font-size: 1.5em;
    }
}

@media(max-width:1024px) {
    nav ul {
        font-size: 0.8em;
    }
    a.nav-logo {
        font-size: 1.2em;
        padding: 0 0 0 $navheight;
        img {
            height: $navheight / 2.5;
        }
    }
}

// MOBILE MENU
@media(max-width:900px) {
    nav .hamburger {
        display: block;
    }
    nav ul,
    .nav-logo {
        float: none;
    }
    a.nav-logo img {
        // width:200px;
        height:20px;
        margin:0 auto;
        top:10px;
        left:16px;
        display:none;
    }
    a.nav-logo {
        padding:0;
    }
    .nav-logo span {
        // display:none;
        // position: fixed;
        // display: block; // background: red;
        // width: 100%;
        // left: 0; // right:0;
        // top: 0;
        // text-align: center; // padding:0 0 0 1em;
    }
    nav ul {
        display: block;
        text-align: center; // z-index:-1;
        position: absolute;
        top: $navheight;
        left: -15%;
        background: rgba(150, 0, 0, 0.9);
        width: 130% !important;
        box-shadow: 0 0 30px #000;
        transition: 0.5s ease-in-out;
    }
    nav ul.close {
        left: -1000px;
    }
    nav ul li {
        // margin:0;
        // padding:0;
        // height:default;
        font-size: 1.5em;
        height: calc((100vh - #{$navheight}) /6);
        line-height: calc((100vh - #{$navheight}) /6);
        display: block;
    }
    nav ul li a {
        margin: 0;
    }
}