.modal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    
    .modal-content {
        background-color: #fefefe;
        margin: 5% auto;
        padding: 60px 2em;
        border: 1px solid #888;
        width: 60%;
        border-radius:10px;
        position: relative;
        
    }
    
    .close {
        color: #aaa;
        position:absolute;
        top:10px;
        right:30px;
        font-size: 28px;
        font-weight: bold;
        &:hover,
        &:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }
    
}