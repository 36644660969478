$black: #292525;
$accent: rgb(255, 30, 30);
$red:#A80000;
$navheight:75px;

@import "inheritance";
// @import "skeleton";
@import "skeleton/skeleton";
@import "who-we-are";
@import "footer";
@import "areas-of-expertise";
@import "home-page";
@import "modal";
@import "navigation";
@import "contact";
// @import "../node_modules/normalize-scss/sass/normalize/import-now"; // import normalize-scss
@import 'https://fonts.googleapis.com/css?family=Lato:400,700';



body {
    background: #000;
    font-size:16px;
    font-family: Lato, sans-serif, Arial, Helvetica;
    // margin-top:200px;
    padding-top:$navheight;
}
body.hide-bg {
    padding-top:0;
}

// html, * {
//     box-sizing: border-box!important;
// }

.container {
    width: 80%;
    margin: 0 auto;
}

header,
footer {
    
    color: #FFF;
}


.main-body {
    background: #FFF;
    // padding: 3em 0 2em 0;
    padding:$navheight 0;
    // margin-top:$navheight;
}




h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

.footer-menu {
    list-style:none;
    li {
        margin:0 0 0.8em 0;
        padding:0;
        a {
            text-decoration: none;
            display: block;
            color:#FFF;
            transition:0.25s;
            &:hover {
                color:$accent;
                transform:translateX(5px);
            }
        }
    }
}

.logo-column {
    text-align: center;
    span {
        font-weight:700;
        font-size:2.5em;
        transition:1s;
    }
    img.bird {
        width:80%;
        margin:0 auto;
        transition:1s;
    }
    img.canada {
        max-width:100px;
        display:block;
        margin:0 auto;
    }

    // &:hover span {
    //     color:$accent;
    // }
    // &:hover img {
    //     transform:translateY(-20px);
    // }
}

.social-menu {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    // justify-content: space-between;
    li {
        display: inline;
        a {
            text-decoration: none;
            display:inline-block;
            color: #FFF;
            font-size: 2.5em;
            transition: 0.2s all;
            margin:0 15px 0 0;
            &:hover {
                color: $accent;
                transform:scale(1.1);
            }
        }
    }
}
.social-menu.dark li a {
    color:$red;
    &:hover {
        color:$accent;
    }
}


.text-center {text-align: center;}
.action-call {
    font-size:2.3em;
    font-weight:700;
    color: $accent;
    text-align: center;
}
.hero-box {
    background-position: center center;
    background-size: cover;
    // background-attachment: fixed;
    box-shadow: 0 0 20px #000;
    
    // margin:0 0 50px 0;

    .content {
        padding:100px 0;
        background: rgba(0,0,0,0.5);
        color: #FFF;
        text-shadow: 0 0 20px #333;
        display:flex;
        justify-content: center;
        align-items: center;
        min-height:100px;
        
    }   
    .content * {
        color: #FFF;
    }

    // OPTIONS FOR HERO
    &.about-us {background-image:url('/img/about-us-hero.jpg');}
    &.expertise {background-image:url('/img/ramzi-expertise.jpg');}
    &.areas-of-expertise {background-image:url('/img/areas-of-expertise.jpg');}
    &.contact-us {background-image:url('/img/contact-us.jpg');}
    &.who-we-are {background-image:url('/img/who-we-are.jpg');}
    
}


.resp-img {
    width:100%;
}
.corners {
    border-radius:5px;
    overflow: hidden;
}
.shadow {
    box-shadow: 0 0 10px #999;
}
.img-caption {
    background: #333;
}
figure {
    background: lighten($black,70);
    margin:1.5em 0;padding:0;
    figcaption {
        font-size:0.8em;
        text-align: center;
        margin:0;padding:0.75em 0;
    }
}

// SPINNER
@keyframes spinner {
    0% {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
}

.spinner {
    height: 300px;
    width: 100%;
    display:block;
    // background: #000;
    margin:1em 0;
    text-align: center;
    line-height: 300px;
    
    
    &:after {
        content:'\f110';
        display:block;
        font-family:FontAwesome;
        color:$accent;
        font-size:100px;
        animation:spinner 1s linear infinite;    
    }
}

// FORM ERRORS
form label.error {
    font-weight: inherit;
    color:red;
}
div.success, div.error {
    // display:none;
    border-radius:5px;
    color:#FFF;
    padding:1em 2em;
    margin:1em auto;

    p {
        margin:0;
        padding:0;
    }

}
div.success {
    background:darken(#85E08F,30) ;
}
div.error {
    background:darken(red,30) ;
}