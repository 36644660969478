%large-icons {
    $circlesize: 200px;
    width: $circlesize;
    height: $circlesize;
    font-size: $circlesize /3; // font-size: 150px;
    color: #FFF;
    background: darken($accent, 20);
    border-radius: 100%;
    line-height: $circlesize;
    transition: 0.25s ease;
    &:hover {
        background: #F00;
        transform: translateY(-10px);
    }
}

.sector-menu {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    a {
        width: 30%;
        text-align: center;
        text-decoration: none;
        font-weight: 700;
        font-size: 1.5em;
        color: #000;
        margin: 0 0 1em 0;

        &:active {
            transform: translateY(1px);
        }

        span {
            display: block;
        }
        i {
            @extend %large-icons;
        }
    }
}

@media(max-width:900px) {
    .sector-menu a {
        width: 50%;
    }
}

@media(max-width:560px) {
    .sector-menu a {
        width: 100%;
        // background: $red;
        // margin:0;padding:0;
    }
    .sector-menu a i {
        $sz: 120px;
        height: $sz;
        width: $sz;
        line-height: $sz!important;
        font-size:$sz/3!important;
        margin:0;padding:0;
        // display:none;
    }
}