
footer {    
    margin:0;
    padding:0;

    p {
        margin:0 0 0.5em 0;
    }

    p strong {
        text-transform: uppercase;
        font-size: 1.2em;
    }

    #newsletter-signup {
        width:100%;
        
    .newsletter {
        display:flex;
        justify-content: center;
        align-items: center;
        position:relative;
        $newsheight: 40px;
        input[type=text] {
            height: $newsheight;
            color:$black;
            width:100%;
        }
        .button-primary {
            padding:0 1em;
            height: $newsheight * 0.8;
            line-height: $newsheight * 0.8;
            font-size:0.7em;
            position:absolute;

            right:($newsheight - ($newsheight * 0.8))/2;
            top:($newsheight - ($newsheight * 0.8))/2;
        }
    }
}

    .black-container {
        padding:75px 0 30px 0;
        background: $black;
    }

    .footer-row {
        padding:1em 0;
        // line-height: 80px;
        font-size: 1.2em;

        a {
            
            text-decoration: none;
            font-weight: 700;
            color:$accent;
        }
        .copyright {
            font-size: 0.6em;
        }

    }

}