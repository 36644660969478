.home-page {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    color:#FFF;
    &::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        content: ' ';
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        background: url('/img/canada-finger.jpg') center center;
        background-size: cover;
        background-attachment: fixed;
        z-index: -1;
    }
    img {
        max-width: 800px;
    }
}

.home-button {
    box-sizing: border-box;
    $bh: 50px;
    display: inline-block; // height: $bh!important;
    // line-height: $bh!important;
    border: 2px solid #FFF;
    background: $red;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
    padding: 1em 30px;
    color: #FFF;
    border-radius: $bh;
    &:hover {
        color: inherit;
    }
}

@media(max-width:900px){
    .home-page {
        box-sizing: border-box;
        text-align: center;
        padding:2em;
    }
}