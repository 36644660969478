// LARGE RED ICONS
%large-icons {
    $circlesize: 200px;
    width: $circlesize;
    height: $circlesize;
    font-size: $circlesize /3; // font-size: 150px;
    text-align: center;
    color: #FFF;
    background: darken($accent, 20);
    border-radius: 100%;
    line-height: $circlesize!important;
    transition: 0.25s ease;
    &:hover {
        background: #F00;
        transform: translateY(-10px);
    }
}